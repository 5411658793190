import { Helmet, HelmetProvider } from "react-helmet-async";
import { Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";
import ScrollToTop from "../src/utils/ScrollToTop";
import "./App.css";
import LoginPage from "./arcView/components/login/LoginPage";
import NotFoundPage from "./arcView/containers/notFound/NotFoundPage";
import OrderHistoryPage from "./arcView/containers/otto/OrderHistoryPage/OrderHistoryPage";
import OrderPackPage from "./arcView/containers/otto/OrderPackPage/OrderPackPage";
import OrderPickPage from "./arcView/containers/otto/OrderPickPage/OrderPickPage";
import OrderPickUpPage from "./arcView/containers/otto/OrderPickUpPage/OrderPickUpPage";
import RetourenPage from "./arcView/containers/otto/RetourenPage/RetourenPage";
import OrderPendingPage from "./arcView/containers/webshop/OrderTable";
import OrderTableOverview from "./arcView/containers/webshop/OrderTableOverview";
import OrderPackedPage from "./arcView/containers/webshop/OrderTablePacked";
import OrderTablePicked from "./arcView/containers/webshop/OrderTablePicked";
import { OrderProvider } from "./arcView/helper/OrderContext";
import AdminLayout from "./arcView/layout/AdminLayout";
import { ProtectedRoute } from "./utils/auth";
import OrderTable from "./arcView/containers/webshop/OrderTable";
import OrderTablePacked from "./arcView/containers/webshop/OrderTablePacked";
import { OrderWebshopProvider } from "./arcView/helper/OrderWebshopContext";
import OrderTableReturned from "./arcView/containers/webshop/OrderTableReturned";
import ProductControl from "./arcView/containers/admin/ProductControl";
import { ProductProvider } from "./arcView/helper/ProductContext";
import CustomProductControl from "./arcView/containers/admin/CustomProductControl";
import { CustomProductProvider } from "./arcView/helper/CustomProductContext";
import { ProductProviderAEB } from "./arcView/helper/ProductContextAEB";
import ProductControlAEB from "./arcView/containers/admin/ProductControlAEB";
import ProductManufacturer from "./arcView/containers/admin/ProductManufacturer";
import ContentControl from "./arcView/containers/admin/ContentControl";
import { ContentProvider } from "./arcView/helper/ContentContext";
import Dashboard from "./arcView/containers/dashboard/Dashboard";
import { AnalyticsProvider } from "./arcView/helper/AnalyticsContext";
import { AnalyticsWebshopProvider } from "./arcView/helper/AnalyticsWebshopContext";
import SocialMediaControl from "./arcView/containers/admin/SocialMediaControl";
import { LinkListProvider } from "./arcView/helper/LinkHelper";

function App() {
	return (
		<Router>
			<ScrollToTop />
			<PageTitleHelmet />
			<OrderProvider>
				<OrderWebshopProvider>
					<CustomProductProvider>
						<ProductProvider>
							<ProductProviderAEB>
								<ContentProvider>
									<AnalyticsProvider>
										<AnalyticsWebshopProvider>
                    <LinkListProvider>
  											<Routes>
  												<Route path="/" element={<AdminLayout />}>
  													<Route path="otto/picken" element={<OrderPickPage />} />
  													<Route path="otto/packen" element={<OrderPackPage />} />
  													<Route path="otto/verpacken" element={<OrderPickUpPage />} />
  													<Route path="otto/historie" element={<OrderHistoryPage />} />
  													<Route path="otto/retouren" element={<RetourenPage />} />
  													{/* <Route
                  path="webshop/bestelluebersicht"
                  element={<OrderTable />}
                /> */}
  													<Route path="webshop/eingang" element={<OrderTable />} />
  													<Route path="webshop/gepickt" element={<OrderTablePicked />} />
  													<Route path="webshop/verpackt" element={<OrderTablePacked />} />
  													<Route path="webshop/retouren" element={<OrderTableReturned />} />
  													<Route path="webshop/historie" element={<OrderTableOverview />} />
  													<Route path="admin/product-control" element={<ProductControl />} />
  													<Route
  														path="admin/custom-content-control"
  														element={<ContentControl />}
  													/>
  													<Route path="admin/product" element={<ProductControlAEB />} />
  													<Route
  														path="admin/product/manufacturer"
  														element={<ProductManufacturer />}
  													/>
  													<Route
  														path="admin/social/links"
  														element={<SocialMediaControl />}
  													/>
  													<Route
  														path="admin/custom-product-control"
  														element={<CustomProductControl />}
  													/>
  												</Route>
  												<Route path="login" element={<LoginPage />} />
  												<Route path="*" element={<NotFoundPage />} />
  											</Routes>
                      </LinkListProvider>
										</AnalyticsWebshopProvider>
									</AnalyticsProvider>
								</ContentProvider>
							</ProductProviderAEB>
						</ProductProvider>
					</CustomProductProvider>
				</OrderWebshopProvider>
			</OrderProvider>
		</Router>
	);
}

function PageTitleHelmet() {
	const location = useLocation();

	// Ein einfacher Weg, um den Seitentitel basierend auf dem Pfad zu setzen
	const pageTitle =
		{
			"/dashboard": "ArcView",
			"/login": "ArcView | Login",
		}[location.pathname] || "ArcView";

	return (
		<HelmetProvider>
			<Helmet>
				<title>{pageTitle}</title>
			</Helmet>
		</HelmetProvider>
	);
}

export default App;
