import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../../../components/ui/dialog";
import { Pencil2Icon, TrashIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../components/ui/table";
import { useLinkList } from "../../helper/LinkHelper";
import { ScrollArea, ScrollBar } from "../../../components/ui/scroll-area";

const SocialMediaControl: React.FC = () => {
  const { links, loading, error, deleteLink, updateLink, addLink } = useLinkList();

  const [newTitle, setNewTitle] = useState("");
  const [newUrl, setNewUrl] = useState("");

  const [editId, setEditId] = useState<number | null>(null);
  const [editTitle, setEditTitle] = useState("");
  const [editUrl, setEditUrl] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isDeleting, setIsDeleting] = useState<number | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const formatUrl = (url: string) => {
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return `https://${url}`;
    }
    return url;
  };

  const handleAddLink = async () => {
    if (!newTitle.trim() || !newUrl.trim()) return;
    setIsAdding(true);
    await addLink(newTitle, formatUrl(newUrl));
    setNewTitle("");
    setNewUrl("");
    setIsAdding(false);
  };

  const handleUpdateLink = async () => {
    if (!editId || !editTitle.trim() || !editUrl.trim()) return;
    setIsUpdating(true);
    await updateLink(editId, editTitle, formatUrl(editUrl));
    setIsUpdating(false);
    setIsDialogOpen(false);
  };

  const handleDeleteLink = async (id: number) => {
    setIsDeleting(id);
    await deleteLink(id);
    setIsDeleting(null);
  };

  if (loading)
    return (
      <div className="flex items-center justify-center gap-2 w-max p-8">
        <span>Links werden geladen</span>
        <div
          className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Links werden geladen...
          </span>
        </div>
      </div>
    );
  if (error) return <p className="text-sm text-red-500">{error}</p>;

  return (
    <section className="w-full p-4 rounded-lg">
      <div className="mb-6 p-4 w-full">
        <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100 mb-4">Neuen Link hinzufügen</h2>
        <div className="flex flex-col sm:flex-row gap-4 w-full">
          <Input type="text" placeholder="Titel" value={newTitle} onChange={(e) => setNewTitle(e.target.value)} className="flex-1" />
          <Input type="text" placeholder="URL" value={newUrl} onChange={(e) => setNewUrl(e.target.value)} className="flex-1" />
          <Button onClick={handleAddLink} className="w-full sm:w-auto" disabled={isAdding}>
            {isAdding ? "Hinzufügen..." : "Hinzufügen"}
          </Button>
        </div>
      </div>
      <div className="overflow-x-auto p-4">
        <ScrollArea className="w-full whitespace-nowrap rounded-md border">
          <ScrollBar orientation="horizontal" />
          <Table className="w-full p-6">
            <TableHeader className="sticky">
              <TableRow>
                <TableHead className="w-1/4">Name</TableHead>
                <TableHead className="w-3/4">Link</TableHead>
                <TableHead className="w-1/6 text-right">Aktionen</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {links?.map((link) => (
                <TableRow key={link.id}>
                  <TableCell>{link.title}</TableCell>
                  <TableCell>
                    <a href={formatUrl(link.url)} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                      {formatUrl(link.url)}
                    </a>
                  </TableCell>
                  <TableCell className="flex justify-end gap-2">
                    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                      <DialogTrigger asChild>
                        <Button
                          variant="outline"
                          onClick={() => {
                            setEditId(link.id);
                            setEditTitle(link.title);
                            setEditUrl(link.url);
                            setIsDialogOpen(true);
                          }}
                        >
                          <Pencil2Icon />
                        </Button>
                      </DialogTrigger>
                      <DialogContent className="p-6 w-full max-w-lg mx-auto">
                        <DialogHeader>
                          <DialogTitle>Link bearbeiten</DialogTitle>
                        </DialogHeader>
                        <div className="flex flex-col gap-4">
                          <Input type="text" value={editTitle} onChange={(e) => setEditTitle(e.target.value)} placeholder="Titel" />
                          <Input type="text" value={editUrl} onChange={(e) => setEditUrl(e.target.value)} placeholder="URL" />
                        </div>
                        <DialogFooter className="flex justify-end gap-3 mt-4">
                          <Button onClick={handleUpdateLink} disabled={isUpdating}>
                            {isUpdating ? "Speichern..." : "Speichern"}
                          </Button>
                        </DialogFooter>
                      </DialogContent>
                    </Dialog>

                    {/* 🗑️ Löschen */}
                    <Button variant="destructive" onClick={() => handleDeleteLink(link.id)} disabled={isDeleting === link.id}>
                      {isDeleting === link.id ? "Löscht..." : <TrashIcon />}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ScrollArea>
      </div>
    </section>
  );
};

export default SocialMediaControl;
