import React, { createContext, useContext, useEffect, useState, ReactNode } from "react";

export interface Link {
  id: number;
  title: string;
  url: string;
}

interface LinkListContextType {
  links: Link[] | null;
  loading: boolean;
  error: string | null;
  fetchLinks: () => void;
  addLink: (title: string, url: string) => Promise<void>;
  updateLink: (id: number, title: string, url: string) => Promise<void>;
  deleteLink: (id: number) => Promise<void>;
}

const LinkListContext = createContext<LinkListContextType | undefined>(undefined);

export const LinkListProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [links, setLinks] = useState<Link[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // 🛠️ Funktion zum Abrufen des Auth-Tokens aus dem localStorage
  const getAuthToken = () => {
    return localStorage.getItem("authToken") || ""; // Falls kein Token vorhanden, leere Zeichenkette
  };

  // 🛠️ Hilfsfunktion zum Erstellen von Headers mit Auth-Token
  const getHeaders = () => ({
    "Content-Type": "application/json",
    Authorization: `Bearer ${getAuthToken()}`,
  });

  // 🚀 Fetch all links
  const fetchLinks = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch("/api/linklist/links", {
        method: "GET",
        headers: getHeaders(),
        credentials: "include",
      });

      if (!response.ok) throw new Error(`Fehler ${response.status}: ${await response.text()}`);

      const data: Link[] = await response.json();
      setLinks(data);
    } catch (error) {
      setError(error instanceof Error ? error.message : "Unbekannter Fehler.");
    } finally {
      setLoading(false);
    }
  };

  // ➕ Add a new link
  const addLink = async (title: string, url: string) => {
    try {
      const response = await fetch("/api/linklist/add-link", {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify({ title, url }),
      });

      if (!response.ok) throw new Error(`Fehler ${response.status}: ${await response.text()}`);

      await fetchLinks(); // Reload data
    } catch (error) {
      setError(error instanceof Error ? error.message : "Fehler beim Hinzufügen.");
    }
  };

  // ✏️ Update a link
  const updateLink = async (id: number, title: string, url: string) => {
    setLoading(true)
    try {
      const response = await fetch(`/api/linklist/update-link/${id}`, {
        method: "PUT",
        headers: getHeaders(),
        body: JSON.stringify({ title, url }),
      });

      if (!response.ok) throw new Error(`Fehler ${response.status}: ${await response.text()}`);

      await fetchLinks();
      setLoading(false)
    } catch (error) {
      setError(error instanceof Error ? error.message : "Fehler beim Aktualisieren.");
    }
  };

  // 🗑️ Delete a link
  const deleteLink = async (id: number) => {
    try {
      const response = await fetch(`/api/linklist/delete-link/${id}`, {
        method: "DELETE",
        headers: getHeaders(),
      });

      if (!response.ok) throw new Error(`Fehler ${response.status}: ${await response.text()}`);

      await fetchLinks(); // Reload data
    } catch (error) {
      setError(error instanceof Error ? error.message : "Fehler beim Löschen.");
    }
  };

  useEffect(() => {
    fetchLinks();
  }, []);

  return (
    <LinkListContext.Provider value={{ links, loading, error, fetchLinks, addLink, updateLink, deleteLink }}>
      {children}
    </LinkListContext.Provider>
  );
};

export const useLinkList = () => {
  const context = useContext(LinkListContext);
  if (!context) throw new Error("useLinkList muss innerhalb eines LinkListProviders verwendet werden.");
  return context;
};
